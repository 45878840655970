.fancy-thead {
  // border-right: 1px solid #fff;
  position: relative;
  cursor: pointer;

  .order-icon {
    position: absolute;
    top: 50%;
    // right: 0;
    transform: translate(0, -50%);
  }

  &.same {
    .order-icon {
      display: none;
    }
  }

  &.asc {
  }

  &.desc {
    .order-icon {
      transform: translate(0, -40%) rotate(180deg);
    }
  }
}
