.edit-dataset-description-main-container {
  width: 650px;
  height: 400px;
}

.edit-dataset-description-dialog-header {
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid var(--detail-border);
  padding: 16px;
}
