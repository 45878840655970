.data-display-section {
  grid-gap: 32px;

  header {
    display: flex;
    flex-direction: column;
    gap: 12px;

    h4 {
      font-size: 24px;
    }
  }

  &.horizontal {
    display: grid;
    grid-template-columns: 1fr 3fr;

    .items-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 48px;

      .data-display-item {
        flex: calc(50% - 48px);
      }
    }
  }

  &.vertical {
    display: flex;
    flex-direction: column;

    .items-container {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;

      .data-display-item {
        flex: calc(33% - 32px);
      }
    }
  }
}
