.table-container {
  display: block;
  flex-direction: column;
  height: 100%;
  --header-height: 50px;
  position: relative;

  .blurred-bar {
    height: 100%;
    width: 1px;
    background-color: white;
    box-shadow: 10px 10px 50px 50px white;
    opacity: 1;
    position: absolute;
    right: 0;
    z-index: 11;

    &.not-visible {
      display: none;
    }
  }

  &.headless {
    --header-height: 0;
  }

  .table-header {
    height: var(--header-height);
  }

  .table-content-container {
    display: flex;
    flex-direction: column;
    height: calc(100% - var(--header-height));

    .table-search-download-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
    }

    .table-controls {
      // background: var(--primary);
      padding: 8px;
      // margin-right: 5px;
      .table-search {
        max-width: 320px;
        margin-left: auto;
      }
    }

    .download-icon {
      background-color: var(--primary);
      width: 48px;
      height: 48px;
      padding: 10px;
      border-radius: 10px;
      color: white;
      cursor: pointer;
      z-index: 12;
    }

    .scrollable-table {
      overflow-y: auto;
      width: 100%;

      .header-visible-observer-container {
        width: 100%;
      }

      &.sm {
        height: 300px;
      }

      &.md {
        height: 640px;
      }

      &.lg {
        height: 1000px;
      }

      table {
        width: 100%;
        min-width: 100%;

        th {
          padding: 16px;
        }

        tbody {
          tr {
            .show-more-button {
              padding: 0 !important;
              background: rgba(255, 255, 255, 0.9) !important;
              z-index: 10;
            }
            background: var(--bg-white);
            cursor: default;
            border-bottom: 1px solid var(--detail-border);
            &:hover {
              background: var(--tertiary);
              .show-more-button {
                background: rgba(247, 251, 252, 0.9) !important;
              }
            }
          }

          // this is the table row with the observed element, we do not wanna see it
          tr:last-child {
            td {
              padding: 0;
            }
          }

          td {
            padding: 20px;
            // word-break: break-word;

            div {
              background-color: transparent !important;
            }

            svg {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
