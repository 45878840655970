.accordion {
  .accordion-item {
    border: 1px solid var(--detail-border);
    box-shadow: var(--box-shadow-small);
    border-radius: 10px;
    margin-bottom: 16px;

    &:last-child {
      border-bottom: 0;
    }
  }

  .accordion-header {
    margin: 0 16px;
  }

  .accordion-button {
    // font styles
    font-size: 16px;
    line-height: 19px;
    color: var(--text-black);
    padding: 16px 0;

    // container styles
    border-bottom: 1px solid var(--detail-border);
    background: transparent;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }

    &.collapsed {
      border-bottom: 0;
    }

    &:not(.collapsed):after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
  }
}
