.chart-component {
  grid-gap: 32px;

  header {
    display: flex;
    flex-direction: column;
    gap: 12px;

    h4 {
      font-size: 24px;
    }
  }

  &.horizontal {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }

  &.vertical {
    display: flex;
    flex-direction: column;
  }
}
