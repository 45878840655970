.open-details-btn {
  font-size: 12px;
  border: none;
  background: transparent;
  border-bottom: 1px solid var(--warning-blue);
  color: var(--warning-blue);
  font-weight: bold;
  padding: 0;
}

.data-set-container {
  --data-set-header-height: 82px;
  --data-set-footer-height: 52px;
  --data-set-content-height: calc(
    100% - var(--data-set-header-height) - var(--data-set-footer-height)
  );

  height: 100%;

  header {
    height: var(--data-set-header-height);
  }

  footer {
    height: var(--data-set-footer-height);
  }

  section {
    height: var(--data-set-content-height);
    padding: 32px 0;
    overflow: hidden;
  }
}
