.table-change-request-header-container {
  justify-content: space-between;
  width: 100%;
  display: flex;

  .table-change-request-header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .table-name-typography {
      display: flex;
      align-items: center;
    }

    .table-status {
      display: flex;
      align-items: center;
    }
  }

  .table-change-request-header-requested-by {
    display: flex;
    gap: 32px;

    div {
      display: flex;
      flex-direction: column;
    }
  }
}

.table-change-request-content-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  footer {
    .reason-container {
      display: flex;
      flex-direction: row;
      gap: 4px;
    }

    .handlers-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 32px;

      .reject-btn {
        color: var(--warning-error);
        border-color: var(--warning-error);
        width: auto !important;
        margin: unset !important;
      }

      .approve-btn {
        background: var(--warning-success);
        width: auto;
      }
    }
  }
}
