.scrollable-aside-main {
  display: flex;

  > aside {
    position: sticky;
    top: 56px;
    margin-top: 56px;
    height: calc(100vh - 56px);
  }

  > main {
    margin-top: 56px;
    width: 100%;
  }
}
