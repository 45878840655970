.pseudo-link {
  width: max-content;
  cursor: pointer;
  border: none;
  background: transparent;
  border-bottom: 1px solid var(--warning-blue);
  color: var(--warning-blue);
  padding: 0;

  &.primary {
    font-size: 16px;
  }

  &.secondary {
    font-weight: bold;
    font-size: 12px;
  }

  &.tertiary {
    font-size: 16px;
    color: var(--primary);
    border-bottom: 1px solid var(--primary);
  }
}
