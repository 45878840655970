.typography {
  margin-bottom: 0 !important;

  &.sm {
    line-height: 14px;
    font-size: 12px;
  }

  &.md {
    line-height: 19px;
    font-size: 16px;
  }

  &.lg {
    line-height: 29px;
    font-size: 24px;
  }

  &.xl {
    line-height: 28px;
    font-size: 32px;
  }

  &.primary {
    color: var(--text-black);
  }

  &.secondary {
    color: var(--text-gray);
  }

  &.tertiary {
    color: var(--primary);
  }

  &.bold {
    font-weight: bold;
  }
}
