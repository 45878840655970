.code-snippet {
  position: relative;

  border: 1px solid var(--detail-border);

  header {
    padding: 8px;
  }

  .cpy-icon {
    position: absolute;
    cursor: pointer;
    bottom: 5px;
    right: 5px;
  }

  pre {
    padding: 8px 8px 24px 8px;
    margin: 0;
  }
}
