.feedback-item-body {
  footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      margin: auto 0 !important;
    }

    button:not(:first-child) {
      margin: auto 0 auto 32px !important;
    }
  }
}
