.category-item-container {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all 200ms ease;

  gap: 16px;

  &:not(.disabled):hover {
    background: var(--tertiary);
  }

  &:not(.disabled) {
    cursor: pointer;
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }

  aside {
    background-color: var(--tertiary);
    height: 50px;
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div {
    margin: 0 6px;
  }
}
