.text-diff {
  line-height: 22px;

  .added-content {
    background: rgba(120, 247, 120, 0.35);
  }

  .removed-content {
    background: rgba(253, 46, 46, 0.35);
    text-decoration: line-through;
  }
}
