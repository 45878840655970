.container {
  .card {
    padding: 0 32px;

    &:not(:first-child) {
      margin-top: 32px;
    }

    .card-body {
      padding: 32px 0;
    }
  }

  .data-distribution-container {
    .column-name {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: var(--text-gray);
    }

    .chart {
      header {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;

        div {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
        }
      }

      article {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
