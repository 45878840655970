.search-container {
  --search-container-y-margin: 24px;

  display: flex;
  flex-direction: column;
  margin: var(--search-container-y-margin);
  padding: 32px 32px 16px 32px;
  background: var(--bg-white);

  // min-height: calc(100% - 2 * var(--search-container-y-margin));
  height: calc(100% - 2 * var(--search-container-y-margin));
  overflow: hidden;

  .search-header {
    height: 210px;
    padding-bottom: 20px;
  }

  .search-data-container {
    height: calc(100% - 210px);
    overflow: hidden;
    display: grid;
    grid-template-columns: 2fr 7fr;
    grid-gap: 4rem;

    .search-filter {
      height: 100%;
      overflow: auto;
    }
  }
}
