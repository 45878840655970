.data-set-list {
  height: 100%;
  padding: 0 12px 0 0;

  display: flex;
  flex-direction: column;
  gap: 24px;

  overflow: auto;
}
