.table-item-container {
  display: flex;

  .metadata-container {
    width: 25%;
    padding-left: 16px;
    height: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 12px;
  }
}

.main-container {
  display: flex;
  border: 1px solid var(--detail-border);
  width: 100%;
  height: 100%;
}

.general-info-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-right: 1px solid var(--detail-border);
  padding-right: 16px;
  height: 100%;
  width: 75%;

  h1 {
    cursor: pointer;
    text-transform: capitalize;
    border-bottom: 1px solid var(--warning-blue);
    width: max-content;
  }

  h2 {
    font-weight: 400;
  }
}

.metadata-inside-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .tag-section {
    display: flex;
    flex-direction: column;
  }
}
