.nav-link {
  color: var(--text-gray) !important;

  height: 40px;
  font-size: 14px;
  display: flex;
  border-radius: 10px;
}

.nav-link.open {
  width: 100%;
}

.nav-link.closed {
  width: fit-content;
}

.nav-link:focus,
.nav-link:hover {
  color: var(--primary) !important;
}

.nav-link.active {
  background-color: var(--primary);
  color: var(--bg-white) !important;
}

.nav-link > .icon {
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.nav-link.open > .icon {
  margin-right: 10px;
}

.nav-link > .icon > img {
  max-width: 20px;
  max-height: 20px;
}

.nav-link.active > .icon > img {
  filter: brightness(2);
}
.nav-link.active > .icon svg > path {
  fill: var(--bg-white) !important;
}
