.search-input {
  position: relative;

  .form-control {
    padding: 12px 16px;
  }

  .helper-icon {
    position: absolute;
    top: 50%;
    right: 12px;

    transform: translate(0, -50%);

    &.actionable {
      cursor: pointer;
    }
  }
}
