.MuiAppBar-root {
  background: var(--bg-white) !important;
  color: var(--text-gray) !important;
  box-shadow: unset !important;

  border-bottom: 3px solid var(--detail-border) !important;

  .MuiButtonBase-root {
    font-weight: bold !important;
  }

  .MuiButtonBase-root:focus {
    border-color: unset !important;
    box-shadow: unset !important;
  }

  .Mui-selected {
    color: var(--primary) !important;
  }

  .MuiTabs-root {
    overflow: unset !important;
  }

  .MuiTabs-scroller {
    overflow: unset !important;
  }

  .MuiTabs-indicator {
    height: 3px;
    bottom: -3px;
    background: var(--primary) !important;
  }
}
