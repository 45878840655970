.no-results-found {
  height: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .no-results-found-description {
    white-space: pre-line;
  }
}
