.container.dashboard {
  padding: 24px;

  .card {
    padding: 0 32px;

    &:not(:first-child) {
      margin-top: 32px;
    }

    .card-body {
      padding: 32px 0;

      &:not(:first-child) {
        border-top: 1px solid var(--detail-border);
      }

      .row .col {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .row .col-12 {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
  }

  .card-subtitle {
    color: var(--text-gray);
  }

  .row.title {
    margin-bottom: 8px;
  }
}
