.metrics-display {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .info-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 0;
  }

  header h6 {
    font-size: 16px;
  }

  .metrics-display-container {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    margin-bottom: 4px;

    .data-value {
      font-size: 32px;
      line-height: 32px;
      font-weight: bold;
    }

    .garbage-icon {
      margin-left: -6px;
      margin-bottom: -6px;
      margin-right: -6px;
    }

    .data-rate {
      font-size: 12px;
      line-height: 12px;
      font-weight: bold;
    }
  }
}
