.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 573px;
  height: calc(90vh - 56px);
}

.login .card {
  width: 100%;
  height: 302px;
  max-width: 420px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--bg-white);
}

.login .row.okta {
  margin-bottom: 32px;
}

.login button.icon-btn {
  color: white !important;
  background-color: #007cc0 !important;
  height: 65px;
  padding-left: 8px;
  padding-right: 32px;
}
