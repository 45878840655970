.toast-container {
  position: fixed !important;

  .toast {
    .toast-header {
      color: var(--text-black);
      position: relative;

      .toast-date {
        position: absolute;
        right: 24px;
        font-size: 10px;
        color: var(--text-gray);
      }

      .btn-close {
        height: 3px;
        width: 3px;
      }

      .toast-body {
        color: var(--text-black);
      }
    }

    &.success {
      border: 1px solid var(--warning-success);
      background: var(--bg-green);
      .toast-header {
        background: var(--bg-green);
        border-bottom: 1px solid var(--warning-success);
      }
    }

    &.error {
      border: 1px solid var(--warning-error);
      background: var(--bg-red);
      .toast-header {
        background: var(--bg-red);
        border-bottom: 1px solid var(--warning-error);
      }
    }

    &.warning {
      border: 1px solid var(--warning-orange);
      background: var(--bg-yellow);
      .toast-header {
        background: var(--bg-yellow);
        border-bottom: 1px solid var(--warning-orange);
      }
    }
  }
}
