.our-input-block {
  position: relative;

  .form-label {
    color: var(--text-gray);
  }

  .our-input {
    font-size: 16px;
    color: var(--text-black);
    border-radius: 5px;
    border: 1px solid var(--detail-border) !important;

    &.has-error {
      border-color: var(--warning-error) !important;
    }

    box-shadow: unset !important;
    &:focus {
    }

    &::placeholder {
      color: var(--text-gray);
    }
  }

  .feedback-invalid {
    font-size: 12px;
    color: var(--warning-error);
    position: absolute;
    bottom: -20px;
    left: 12px;
  }
}
