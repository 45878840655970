.container.data-topic {
  padding: 24px;

  // ideally, that would not happen, we would probably have a variant on this select
  .market-selector select {
    font-size: 24px;
    font-weight: bold;
    color: var(--text-gray);
    padding-bottom: 0;
  }

  .nav-content-container {
    padding-left: 24px;
  }

  .card {
    &:not(:first-child) {
      margin-top: 32px;
    }
  }
}
