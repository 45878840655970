.title {
  line-height: 64px;
  font-weight: 700;
  margin-bottom: 0;

  &.primary {
    color: var(--primary);
  }

  &.secondary {
    color: var(--text-gray);
  }

  &.tertiary {
    color: var(--text-black);
  }

  &.quaternary {
    color: var(--warning-blue);
  }

  &.sm {
    font-size: 16px !important;
    line-height: 19px;
  }

  &.sm-md {
    font-size: 24px !important;
    line-height: 40px;
  }

  &.md {
    font-size: 32px !important;
    line-height: 64px;
  }

  &.lg {
    font-size: 48px !important;
    line-height: 58px;
  }
}
