.data-list-container {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style: none;
    padding: 0 0 12px 0;
  }
}
