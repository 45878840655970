.our-select-block {
  .form-label {
    color: var(--text-gray);
  }

  .our-select {
    font-size: 16px;
    color: var(--text-black);

    &:focus {
      box-shadow: none !important;
    }

    &:required:invalid {
      color: var(--text-gray);
    }

    option[disabled] & {
      color: var(--text-gray) !important;
    }
  }

  &.primary {
    .our-select {
      border: 1px solid var(--detail-border);
    }
  }

  &.secondary {
    &.sm label {
      margin-left: 8px;
      margin-bottom: 0;
      font-size: 12px;
    }

    &.lg label {
      margin-left: 16px;
    }

    .our-select {
      border: none !important;
      border-bottom: 1px solid var(--detail-border) !important;
      border-radius: 0 !important;
    }
  }
}
