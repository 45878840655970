.side-menu-nav {
  height: calc(100vh - 10rem);
  display: flex;
  flex-direction: column;
  gap: 64px;

  min-width: 180px;

  .MuiTab-root {
    align-items: unset !important;
    text-align: left !important;
    display: list-item !important;
    font-weight: bold !important;
    font-size: 16px !important;
    white-space: nowrap;
  }

  .button-link {
    text-decoration: none;
    color: var(--text-gray) !important;
    &.active {
      color: black !important;
    }
  }

  border-right: 1px solid var(--detail-border);
}
