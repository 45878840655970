.step-instruction {
  display: flex;

  gap: 16px;

  .step {
    flex-shrink: 0;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background: var(--primary);

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 24px;
    font-weight: bold;
    color: var(--bg-white);
  }

  .content {
    color: var(--text-black);

    h4 {
      font-weight: bold;
    }
  }
}
