.nav {
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  background-color: var(--bg-white);
  padding-left: 24px;
  padding-right: 24px;
  overflow: hidden;

  box-shadow: var(--box-shadow-small);
}

.nav.open {
  width: 270px;
  transition: width 0.1s ease-in-out;
}

.nav.closed {
  width: 104px;
  overflow: hidden;
  transition: width 0.1s ease-in-out;
}

.nav > .icon-btn {
  margin-left: 16px;
  white-space: nowrap;
}

.nav > #open-close-menu.icon-btn {
  margin-top: 94px;
  margin-bottom: 94px;
  font-weight: 700;
  font-size: 16px;
}

.nav .nav-link:not(:last-child) {
  margin-bottom: 40px;
}

.nav > #logout.icon-btn {
  position: fixed;
  left: 24px;
  bottom: 35px;
  font-weight: 700;
  font-size: 16px;
}
