.facet-item {
  padding: 0.5rem 0;

  .facet-title {
    border-bottom: 1px solid var(--detail-border);
  }

  .form-check {
    padding-bottom: 0.3rem;
  }
}
