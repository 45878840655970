.card-container {
  border: 1px solid var(--detail-border);
  height: 100%;
}

.clickable {
  cursor: pointer;
}

.card-container.primary {
  box-shadow: var(--box-shadow-small);
  padding: 16px;
  border-radius: 10px;
}

.card-container.secondary {
  border: 1px solid var(--detail-border);
  padding: 12px;
}
