.table-status-container {
  display: flex;
  align-items: flex-start;

  p {
    font-weight: 400;
    font-size: 12px;
    color: var(--text-gray);
    margin: 0 !important;
  }

  // CERTIFIED/UNCERTIFIED REMOVED FOR NOW
  // .table-health {
  //   border-right: 1px solid var(--detail-border);
  // }

  .table-certified,
  .table-health {
    font-weight: bold;
    font-size: 12px;
    // padding: 0 8px; CERTIFIED/UNCERTIFIED REMOVED FOR NOW

    &.success {
      color: var(--warning-success);
    }

    &.intermediate {
      color: var(--warning-orange);
    }

    &.fail {
      color: var(--warning-error);
    }

    &.unavailable {
      color: var(--warning-disable);
    }
  }

  .table-health {
    padding-left: 0;
  }

  .table-certified {
    padding-right: 0;
  }

  .status-date {
    margin-left: 24px !important;
  }
}
