.our-input-block {
  .form-label {
    color: var(--text-gray);
  }

  .our-input {
    font-size: 16px;
    color: var(--text-black);
    border-radius: 5px;
    border: 1px solid var(--detail-border);

    &::placeholder {
      color: var(--text-gray);
    }
  }
}
