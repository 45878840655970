.aside-main {
  display: flex;
  height: calc(100% - 56px);
  overflow: hidden;
  width: 100%;
  margin-top: 56px;

  > aside {
    height: calc(100vh - 56px) !important;
    position: sticky;
    height: 100%;
  }

  > main {
    height: 100%;
    width: 100%;
  }
}

#root:has(.aside-main) {
  overflow: hidden;
}
