.navbar {
  height: 56px;
  position: fixed;
  width: 100%;
  padding-top: 5.5px;
  padding-bottom: 5px;

  .env-band {
    position: absolute;
    display: inline;
    background: var(--bg-white);
    color: var(--primary);
    font-weight: 800;
    font-size: 16px;
    padding: 4px;
    width: 100px;
    text-align: center;

    top: 2px;
    left: -35px;
    transform: rotate(-45deg);
  }
}

.navbar .app-name {
  font-size: 12px;
  color: var(--bg-white);

  margin-left: 16px;
}

.navbar > .container-fluid {
  padding-left: 64px;
  padding-right: 30px;
}

.navbar > .container-fluid .user-container {
  display: flex;
  align-items: center;
}

.navbar > .container-fluid .user-container .notification-bell {
  margin-right: 80px;
}
