.data-set-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .data-set-item-tags {
    position: absolute;
    right: 16px;
    top: 24px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
