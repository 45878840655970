.top-nav-container {
  height: 100%;
  overflow: hidden;

  .top-nav-header {
    height: 51px;
  }

  .top-nav-content-container {
    height: calc(100% - 51px);
    overflow: hidden;

    > div {
      height: 100%;
    }
  }
}
