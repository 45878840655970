// This is for development, helps debugging, since react app - with hotreloading - is placed inside an iframe
iframe {
  pointer-events: none;
}

:root {
  --primary: #003b46;
  --secondary: #c6ecf3;
  --tertiary: #f7fbfc;

  --na-primary: #194264;

  --text-black: #424242;
  --text-gray: #858e93;

  --detail-gray: #a1acb2;
  --detail-border: #cdcdcd;

  --warning-error: #ff4949;
  --warning-success: #4aa04a;
  --warning-orange: #fa9f47;
  --warning-blue: #4994ee;
  --warning-disable: #dddddd;

  --bg-white: #ffffff;
  --bg-gray: #f5f5f5;
  --bg-yellow: #fff3d6;
  --bg-red: #fff0f0;
  --bg-green: #cdfcbd;
  --bg-blue: #e4effd;

  --box-shadow-small: 0px 2px 4px #b3b3b3;
}

body {
  margin: 0;
  background-color: var(--tertiary);
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fancy-scrollbar {
  &::-webkit-scrollbar {
    background: var(--bg-white);
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--detail-border);
    border-radius: 16px;
  }
}

.container {
  padding: 24px;
}

.card {
  background: var(--bg-white);
  border-radius: 10px;
  box-shadow: var(--box-shadow-small);
}

.bg-primary {
  background-color: var(--primary) !important;
}
