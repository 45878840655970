.changes-request-accordions-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.changes-request-single-accordion-container {
  height: 100%;
  overflow: auto;
}
