.links-display-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.links-display {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  align-items: center;

  li {
    list-style: none;
    color: var(--warning-blue);

    &:not(:first-child) {
      margin-left: 6px;
      padding-left: 6px;
    }
  }
}
