.navbar-metrics-filter {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 16px;

  margin-right: auto;

  #affiliate {
    width: 256px;

    background-color: transparent;
    color: var(--bg-white);

    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23fff'><polygon points='0,0 100,0 50,50'/></svg>")
      no-repeat;
    background-size: 8px;
    background-position: calc(100% - 8px) 17px;
    background-repeat: no-repeat;

    option {
      color: var(--text-black);
    }
  }

  #market {
    width: 256px;

    .select-button {
      color: var(--bg-white);
      background: transparent;
    }
  }
}
