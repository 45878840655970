.drawer-container {
  width: 550px;
  height: 100%;

  display: flex;
  flex-direction: column;

  overflow: hidden;
  padding: 24px;

  .drawer-header {
    height: 80px;
    padding: 12px 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .drawer-close {
      cursor: pointer;
      display: flex;
      font-weight: bold;
    }
  }

  .drawer-content-container {
    height: 100%;
    padding: 12px 0;
    overflow: hidden;
  }
}
