.container.data-set-details {
  padding: 24px;

  .card {
    &:not(:first-child) {
      margin-top: 32px;
    }

    .card-body {
      padding: 32px 0;
    }
  }

  .metadata-button {
    display: flex;
    align-items: center;
    font-weight: 400;
    gap: 5px;
    font-size: 16px;
    line-height: 19px;
    color: var(--warning-blue);
    background: none;
    border: none;
    width: max-content;
    height: max-content;
    margin-top: 0 !important;
  }

  .details-portal-container {
    position: sticky;
    top: 50px;
    max-height: 60px;
    overflow-y: hidden;
    z-index: 11;
    table {
      width: 100%;
      tbody {
        td {
          padding: 20px;
        }
      }
    }
  }
}

.pending-changes-container {
  background: var(--bg-blue);
  padding: 15px;
}

.description-container {
  display: flex;
  justify-content: space-between;

  svg {
    fill: var(--primary);
    cursor: pointer;
  }
}
