.top-table-menu-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tags-table {
  max-height: 600px;
}

.add-tag-icon {
  background-color: var(--primary);
  width: 48px !important;
  height: 48px !important;
  padding: 10px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  z-index: 12;
}

.useful-links-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add-document-button {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
