.feedback-list-container {
  height: 100%;
  overflow: auto;
  padding-right: 12px;
}

.feedbacks-accordions-container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;

  .accordionv2 {
    height: 56px;

    &.active {
      height: calc(100% - (2 * 56px));
    }
  }
}
