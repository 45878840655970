.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 56px);
}

.not-found h2,
.not-found h4 {
  color: var(--text-gray);
}

.not-found h1 {
  font-weight: bold;
  font-size: 144px;
  color: var(--primary);
  margin-bottom: 23px;
}

.not-found h2 {
  font-weight: bold;
  font-size: 48px;
  margin-bottom: 15px;
}

.not-found h4 {
  font-size: 20px;
  margin-bottom: 102px;
}

.not-found .btn {
  width: 100%;
  height: 48px;
  max-width: 360px;
  padding-top: 11px;
}
