.chips {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;

  .add-chips-button {
    margin-top: 4px;
    cursor: pointer;
  }

  .item {
    position: relative;
    padding: 0.1rem 0.8rem;
    background: var(--tertiary);
    color: var(--text-black);
    border-radius: 2.8rem;
    margin: 0.2rem 0.3rem;

    &:not(.read-only) {
      padding-right: 1.8rem;
    }

    .delete-icon-wrapper {
      position: absolute;
      right: 0.5rem;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
    }
  }
}
