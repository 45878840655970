.row.user-details {
  margin-bottom: 0;
  margin-right: 0;
}

.row.user-details .col {
  color: var(--bg-white);
  text-align: right;
  padding-left: 10px;
  padding-right: 0;
}

.row.user-details .name {
  font-size: 16px;
  margin-bottom: unset;
}

.row.user-details .email {
  font-size: 14px;
  font-weight: 400;
}

.row.user-details .col.picture-container {
  display: flex;
  align-items: center;
}

.row.user-details .picture {
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
  background-color: var(--secondary);
  color: var(--primary);
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}
