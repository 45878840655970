.details-renderer-container {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 2fr 7fr;

  .side-menu-nav {
    top: 10rem;
    position: sticky;
  }
}
