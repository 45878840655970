.change-requests-container {
  height: 100%;
  overflow: hidden;

  > .card {
    height: 100%;
    overflow: hidden;

    .card-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      > header {
        height: 112px;
      }

      > .search-data-container {
        height: calc(100% - 112px);
        overflow: hidden;

        .requested-changes-filter-container {
          height: 100%;
          overflow: hidden;

          .requested-changes-filter {
            height: calc(100% - 20px);
            overflow: auto;

            padding-right: 12px;
          }

          .clear-filters {
            height: 20px;
          }
        }

        display: grid;
        grid-template-columns: 2fr 7fr;
        grid-gap: 40px;
      }
    }
  }
}
