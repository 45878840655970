.form-check-input[type='checkbox'] {
  border-radius: 2px !important;
}

.form-check-input {
  border-color: var(--primary);

  .checkbox-label {
    font-size: 16px;
    color: var(--text-black);
  }
}

.form-check-input:checked {
  background-color: var(--primary);
}
