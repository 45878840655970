.subtitle {
  font-weight: 700;
  color: var(--text-gray);
  margin-bottom: 0 !important;
  margin-top: -0.7rem !important;

  &.primary {
    color: var(--primary);
  }

  &.secondary {
    color: var(--text-gray);
  }

  &.tertiary {
    color: var(--text-black);
  }

  &.sm {
    font-size: 16px !important;
  }

  &.md {
    font-size: 24px !important;
  }

  &.lg {
    font-size: 32px !important;
  }
}
