.our-btn {
  border: none;
  border-radius: 10px;

  &.sm {
    font-size: 12px;
    padding: 6px 24px;
  }

  &.md {
    font-size: 16px;
    padding: 12px 48px;
  }

  &.primary {
    color: var(--bg-white);
    background: var(--primary);
  }

  &.secondary {
    color: var(--bg-white);
    background: var(--primary);
    border: 1px solid var(--bg-white);
  }

  &.tertiary {
    background-color: var(--tertiary);
    color: var(--text-black);
  }

  &.quaternary {
    width: max-content !important;
    padding: 0 !important;
    border-radius: 0;
    margin: auto;
    background: transparent;
    color: var(--na-primary);
    border-bottom: 1px solid var(--na-primary);
  }

  &:disabled {
    background: var(--tertiary);
    color: var(--detail-gray);
  }
}
