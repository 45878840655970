.breadcrumbs {
  a {
    font-size: 12px;
    color: var(--text-gray);
    font-weight: bold;
    text-underline-offset: 3px;
  }

  .css-1wuw8dw-MuiBreadcrumbs-separator {
    margin-left: 4px;
    margin-right: 4px;
  }

  li:last-child a {
    color: var(--text-black);
    text-decoration: none;
  }
}
