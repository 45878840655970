.feedback-header {
  width: 100%;
  display: flex;
  flex-direction: row;

  .info-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .feedback-status {
      font-size: 12px;

      display: flex;
      align-items: center;
      font-weight: bold;

      svg {
        margin-right: 3px;
      }

      &.pinned {
        color: var(--warning-blue);
      }

      &.seen {
        color: var(--warning-success);
      }

      &.unseen {
        color: var(--warning-orange);
      }
    }
  }

  .metadata-container {
    display: flex;
    flex-direction: column;
    gap: 4px;

    white-space: nowrap;

    margin-left: auto;
    margin-right: 56px;
  }
}
