.data-linage-box {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 270px;
  position: relative;

  background-color: var(--bg-white);
  border-radius: 4px;
  box-shadow: var(--box-shadow-small);
  padding: 12px;

  cursor: default;

  > div {
    display: flex;
    justify-content: space-between;
  }

  &:hover {
    background-color: var(--tertiary);
  }

  .options-button {
    cursor: pointer;
  }

  .add-button {
    position: absolute;
    bottom: 38%;
    color: transparent;
    cursor: pointer;
    z-index: 10;

    &:hover {
      color: var(--primary-color);
    }

    &.left {
      left: -12px;
    }

    &.right {
      right: -12px;
    }
  }
}

.handle-dot {
  background: transparent !important;
}
